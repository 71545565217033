import axios from "axios";
import {useEffect, useState} from "react";

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import rankingStyle from "./Ranking.module.css";
import {CssButton} from "../mui-styles/styles";

const API_URL = 'https://metlife-quiz-api-574969884775.europe-west1.run.app/leaderboard';

function Ranking() {
    const [rankingData, setRankingData] = useState(null);
    const [startIndex, setStartIndex] = useState(0);

    const handleNext = () => {
        const newIndex = Math.min(startIndex + 5, rankingData["leaderBoard"].length - 1);
        setStartIndex(newIndex);
    };

    const handlePrev = () => {
        const newIndex = Math.max(startIndex - 5, 0);
        setStartIndex(newIndex);
    };

    useEffect(() => {
        const getLeaderboard = async () => {
            try {
                const response = await axios.get(API_URL);
                setRankingData(response.data);
            } catch (error) {
                console.error('Errore durante il recupero dei dati della leaderboard:', error);
            }
        };

        getLeaderboard();

        /*// Avvia il polling
        const intervalId = setInterval(getLeaderboard, 5000);

        // Funzione di pulizia che viene chiamata quando il componente viene dismontato
        return () => clearInterval(intervalId);*/
    }, []);


    if(rankingData) {
        return (
            <div style={{
                width: '100vw',
                height: '100vh',
                color: '#000',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src={'/logo.svg'} style={{top: '5vh', position: 'absolute'}}/>
                {/*<div className={rankingStyle.rankingHeader}>
                    <h2 style={{marginBottom: 0}}>TOTAL PLAYERS: {rankingData.totalPlayers}</h2>
                </div>*/}
                <div className={rankingStyle.rankingHeader}>
                    <h2>Classifica</h2>
                    <h2 style={{width:'30%', textAlign:'center'}}>Punteggio</h2>
                </div>
                {rankingData["leaderBoard"].slice(startIndex, startIndex + 5).map((element, index) => (
                    <div className={rankingStyle.rankingRow} key={element.country}>
                        <div className={rankingStyle.countryHeader}>
                            <h2 className={rankingStyle.rankingCountry}>
                                {startIndex + index + 1}. {element.team}

                            </h2>
                            {/* Render flag images */}
                        </div>
                        <h2 className={rankingStyle.score}>{element.score ? element.score : ''}</h2>
                    </div>
                ))}
                <div style={{display: 'flex', flexDirection: 'row', columnGap: '3vw', marginTop: '5vh'}}>
                    <CssButton sx={{width: '20vh'}} onClick={handlePrev}
                               disabled={startIndex === 0}>Previous</CssButton>
                    <CssButton sx={{width: '20vh'}} onClick={handleNext}
                               disabled={startIndex + 5 >= rankingData["leaderBoard"].length}>Next</CssButton>
                </div>

            </div>
        )
    }

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            color: '#000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <img src={'/logo.svg'} style={{top: '5vh', position: 'absolute'}}/>
            <h1>Caricamento in corso...</h1>
        </div>
    )
}

export default Ranking;
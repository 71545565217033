import {useEffect, useState} from "react";
import QUESTIONS from "../data/questions";
import axios from "axios";
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {teams} from "../data/teams";
import questionStyle from '../components/Question.module.css';
import {CssButton, CssFormControl, CssSelect} from "../mui-styles/styles";

const API_URL = 'https://metlife-quiz-api-574969884775.europe-west1.run.app/add';

function Home() {

    const [team, setTeam] = useState('');

    const [started, setStarted] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [userAnswer, setUserAnswer] = useState([]);
    const [score, setScore] = useState(null);

    const activeQuestionIndex = userAnswer.length;
    const quizIsComplete = activeQuestionIndex === QUESTIONS.length;

    const correctAnswers = ['A', 'B', 'A', 'C', 'B', 'C', 'C', 'A', 'C'];

    useEffect(() => {
        if(quizIsComplete) {
            const score = calculateScore();
            const userInfo = {
                team: team,
                answers: userAnswer,
                score: score
            }
            console.info(userInfo);
            sendUserInfo(userInfo);
            setScore(score);
        }
    }, [quizIsComplete]);

    const startQuiz = () => {
        // Logic to show quiz questions
        setStarted(true);
    };

    function calculateScore() {
        let score = 0;
        for (let i = 0; i < userAnswer.length; i++) {
            if (userAnswer[i] === correctAnswers[i]) {
                score += 10; // Aggiungi 10 punti per ogni risposta corretta
            }
        }
        return score;
    }

    function handleSelectAnswer(answer) {
        setSelectedAnswer(answer);
    }

    function handleConfirmAnswer() {
        setUserAnswer((prevUserAnswers) => {
            return [...prevUserAnswers, selectedAnswer[0]];
        })
        setSelectedAnswer('');
    }

    const sendUserInfo = async (data) => {
        try {
            const response = await axios.post(API_URL, data);
            console.log('Dati aggiunti con successo', response.data);
        } catch (error) {
            console.error('Errore durante l\'aggiunta dei dati', error);
            // Gestisci qui l'errore
        }
    };

    if(quizIsComplete) {
        // Handle quiz completition
        return (
            <div style={{
                width: '100vw',
                height: '100vh',
                color: '#000',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src={'/logo.svg'} style={{top: '5vh', position: 'absolute'}}/>
                <h1>Grazie per aver giocato!</h1>
            </div>
        )
    }


    return (
        <div style={{
            width:'100vw',
            height:'100vh',
            color:'#000',
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center'
        }}>
            <img src={'/logo.svg'} style={{top:'5vh', position:'absolute'}} />
            {!started &&
                <div style={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center'
                }}>
                    <form>
                        <div>
                            <h3>Seleziona il tuo team ed inizia il quiz!</h3>
                            <CssFormControl variant="outlined" sx={{width:'100%'}}>
                                <InputLabel id="age">TEAM</InputLabel>
                                <CssSelect
                                    labelId="team"
                                    label="TEAM"
                                    value={team}
                                    onChange={(e) => setTeam(e.target.value)}
                                >
                                    {teams.map((el) => (
                                        <MenuItem value={el}>{el}</MenuItem>
                                    ))
                                    }
                                </CssSelect>
                            </CssFormControl>
                        </div>
                    </form>
                    <CssButton sx={{marginTop:'3vh'}} onClick={startQuiz} disabled={!team}>GIOCA</CssButton>
                </div>
            }
            {started &&
                <div className={questionStyle.questionContainer}>

                    <h2 className={questionStyle.question}>{QUESTIONS[activeQuestionIndex].text}</h2>
                    <ul style={{listStyleType: 'none', paddingInlineStart: '0px', margin: 0}}>
                        {QUESTIONS[activeQuestionIndex].answers.map((answer) => (
                            <li
                                className={`${questionStyle.option} ${selectedAnswer[0] === answer[0] ? questionStyle.selected : ''}`}
                                onClick={() => {
                                    handleSelectAnswer(answer)
                                }}
                            >
                                <p>{answer[1].toUpperCase()}</p>
                            </li>
                        ))}
                    </ul>
                    <h3 style={{marginTop: 0}}>{activeQuestionIndex + 1} / 9</h3>
                    <CssButton onClick={handleConfirmAnswer} disabled={!selectedAnswer} style={{}}>Confirm</CssButton>
                </div>
            }

        </div>
    )
}

export default Home;
import Home from "./pages/Home";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Ranking from "./pages/Ranking";

const router = createBrowserRouter([
  { path: '/ranking', element: <Ranking/>},
  { path: '/', element: <Home/>}
])

function App() {
  return <RouterProvider router={router} />;
}


export default App;

export const teams = [
    "TEAM1",
    "TEAM2",
    "TEAM3",
    "TEAM4",
    "TEAM5",
    "TEAM6",
    "TEAM7",
    "TEAM8",
    "TEAM9",
    "TEAM10",
    "TEAM11",
    "TEAM12",
    "TEAM13",
    "TEAM14",
    "TEAM15",
    "TEAM16",
    "TEAM17",
    "TEAM18",
    "TEAM19",
    "TEAM20",
    "TEAM21",
    "TEAM22",
    "TEAM23",
    "TEAM24",
    "TEAM25",
    "TEAM26",
    "TEAM27",
    "TEAM28",
    "TEAM29",
    "TEAM30",
    "TEAM31",
    "TEAM32"
];

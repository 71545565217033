const QUESTIONS = [
    {
        id: 'q1',
        text: 'La Polizza DB Salute è un prodotto che include tra le sue garanzie un indennizzo in caso di intervento chirurgico.\n' +
            'Quanti sono gli interventi chirurgici coperti?\n',
        answers: [
            ['A', 'oltre 1800'],
            ['B', 'circa 1500'],
            ['C', 'circa 1748']
        ]
    },
    {
        id: 'q2',
        text: 'La polizza Key Man è dedicata ai liberi professionisti e titolari di p.iva e piccole aziende assicurando fino a quattro figure chiave.\n' +
            'È una copertura completa contro il rischio di temporanea o definitiva perdita del contributo del Key Man al fatturato dell’azienda stessa.\n' +
            'Tra quanti piani di capitale assicurato è possibile scegliere?',
        answers: [
            ['A', '3'],
            ['B', '4'],
            ['C', '5']
        ]
    },
    {
        id: 'q3',
        text: 'La polizza DB Infortuni include un indennizzo in caso di decesso da infortunio, con raddoppio in caso di incidente stradale e triplicazione in caso di incidente sui mezzi pubblici; un indennizzo per invalidità totale e permanente da infortunio.\n' +
            'Quando si attiva la garanzie invalidità permanente totale da infortunio?',
        answers: [
            ['A', 'Invalidità pari o superiore al 66%'],
            ['B', 'Invalidità pari o superiore al 60%'],
            ['C', 'Invalidità superiore al 80%']
        ]
    },
    {
        id: 'q4',
        text: 'QUANTI ERAVAMO OGGI IN PLATEA?',
        answers: [
            ['A', '264'],
            ['B', '297'],
            ['C', '344']
        ]
    },
    {
        id: 'q5',
        text: 'QUANTI RELATORI SONO SALITI OGGI SUL PALCO?',
        answers: [
            ['A', '12'],
            ['B', '15'],
            ['C', '16']
        ]
    },
    {
        id: 'q6',
        text: 'QUANTE MEDAGLIE HA VINTO L’ITALIA?',
        answers: [
            ['A', '40'],
            ['B', '71'],
            ['C', '111']
        ]
    },
    {
        id: 'q7',
        text: 'IL FILM ROMANO PIÙ PREMIATO DI SEMPRE?',
        answers: [
            ['A', 'LA DOLCE VITA'],
            ['B', 'VACANZE ROMANE'],
            ['C', 'LA GRANDE BELLEZZA']
        ]
    },
    {
        id: 'q8',
        text: 'COME SI CHIAMA L’OTTAVO RE DI ROMA?',
        answers: [
            ['A', 'TITO TAZIO'],
            ['B', 'FRANCESCO TOTTI'],
            ['C', 'OTTAVIANO AUGUSTO']
        ]
    },
    {
        id: 'q9',
        text: 'LA CANZONE ROMANA PER ECCELLENZA?',
        answers: [
            ['A', 'ROMA CAPOCCIA - Antonello Venditti'],
            ['B', 'PORTA PORTESE - Claudio Baglioni'],
            ['C', 'ROMA NUN FA LA STUPIDA STASERA - Lando Fiorini']
        ]
    }
]

export default QUESTIONS;